import React, { useState, useEffect } from 'react';
import { ThemeContext, themes } from './themeContext';

export default function ThemeContextWrapper(props) {
    const [theme, setTheme] = useState( themes.dark );

    function changeTheme(theme) {
        setTheme(theme);
    }

    useEffect(() => {
        let darkText = document.querySelector('.darkText');
        let lightText = document.querySelector('.lightText');

        switch (theme) {
            case themes.light:
                document.body.classList.add('white-content');
                darkText.style.display = "none";
                lightText.style.display = "inline-block";
                break;
            case themes.dark:
            default:
                document.body.classList.remove('white-content');
                lightText.style.display = "none";
                darkText.style.display = "inline-block";

                break;
        }
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
            {props.children}
        </ThemeContext.Provider>
    );
}