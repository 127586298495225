import React, { useState, useEffect, useRef, useCallback } from 'react';
import sanityClient from "../../client.js"
import imageUrlBuilder from '@sanity/image-url'

import { LocomotiveScrollProvider } from 'react-locomotive-scroll'

import { Navigation, Pagination, A11y, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { gsap, Sine, Power1 } from "gsap";

import { LazyLoadImage } from "react-lazy-load-image-component";

import arrow from "../../assets/images/arrow.png";

import "./archive.scss";


function Archive() {
    const [archiveData, setArchiveData] = useState(null);
    const [archiveSwiper, setArchiveSwiper] = useState(null);
    const containerRef = useRef(null)

    const builder = imageUrlBuilder(sanityClient)

    let archiveGallery;
    let archiveGalleryFields;

    const setIdx = (e) => parseInt(e.target.id);

    function urlFor(source) {
        return builder.image(source)
    }

    const archiveSliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!archiveSliderRef.current) return;
        archiveSliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!archiveSliderRef.current) return;
        archiveSliderRef.current.swiper.slideNext();
    }, []);

    let clickedIndex;

    const handleIdx = useCallback((e) => {
        setIdx(e);
        if (!archiveSliderRef.current) return;
        archiveSliderRef.current.swiper.slideTo(clickedIndex);
    }, []);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "archive"] | order(publishedAt desc){
                "imageSet": imageSet[].asset->url,
                "imageSetFields": imageSet[],
            }`)
            .then((archiveData) => setArchiveData(archiveData))
            .catch(console.error);

    }, []);

    useEffect(() => {
        const keydownHandler = e => {
            if (archiveSwiper && e.keyCode == 39) {
                e.preventDefault();
                archiveSwiper.slideNext();
            }

            if (archiveSwiper && e.keyCode == 37) {
                e.preventDefault();
                archiveSwiper.slidePrev();
            }
        }

        document.addEventListener('keydown', keydownHandler);

        return () => {
            document.removeEventListener('keydown', keydownHandler);
        }
    }, [archiveSwiper])


    let archiveSlider;
    let archiveGal;

    if (archiveData) {
        archiveSlider = document.getElementsByClassName('archiveSwiper');
        archiveGal = document.getElementsByClassName('archiveGallery');
    }

    function toggleSlideshow(e) {
        e.preventDefault();
        archiveSlider[0].style.visibility = 'visible';
        archiveGal[0].style.visibility = 'hidden';
        archiveGal[0].style.height = 0 + 'px';
        archiveGal[0].style.overflow = 'hidden';
        window.scrollTo(0, 0);
    }

    function exitSlideshow(e) {
        e.preventDefault();
        archiveGal[0].style.height = 'auto';
        archiveGal[0].style.overflow = 'visible';
        archiveSlider[0].style.visibility = 'hidden';
        archiveGal[0].style.visibility = 'visible';
    }



    if (archiveData != null) {

        archiveGallery = archiveData[0].imageSet;

        archiveGalleryFields = archiveData[0].imageSetFields;

        console.log(archiveGallery)

        return (
            <>
                {/* <Swiper
                    ref={archiveSliderRef}
                    modules={[Navigation, Pagination, A11y, Virtual]}
                    spaceBetween={5}
                    slidesPerView={1}
                    className="archiveSwiper"
                    navigation={{
                        prevEl: '.prev-arrow',
                        nextEl: '.next-arrow'
                    }}
                    keyboard={{
                        enabled: true,
                        onlyInViewport: false
                    }}
                    mousewheel={true}
                    onSwiper={setArchiveSwiper}
                    initialSlide={clickedIndex}
                    speed={1500}
                    loop={true}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 200
                        },
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 40,
                        }
                    }}
                > */}
                {/* <div className="prev-arrow" onClick={handlePrev}>
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1181.000000 1181.000000" width={30} height={30} style={{ rotate: "90deg" }}
                            className="arrow-logo" fill="black" stroke="none">
                            <g transform="translate(0.000000,1181.000000) scale(0.100000,-0.100000)">
                                <path d="M2015 8360 c-102 -8 -147 -25 -193 -71 -68 -69 -63 121 -60 -2369 l3
                                            -2255 22 -58 c26 -70 56 -108 108 -139 l40 -23 655 0 655 0 57 23 c102 41 147
                                            114 135 219 -6 58 -31 92 -88 120 -76 39 -182 46 -583 41 -402 -6 -454 -2
                                            -525 45 -56 37 -75 100 -61 200 7 46 10 716 10 1887 0 1149 3 1827 9 1848 19
                                            63 50 100 108 129 l57 27 410 -2 c260 -2 431 1 466 8 133 26 195 87 195 194 0
                                            47 -5 68 -23 93 -26 37 -88 69 -161 83 -60 11 -1087 11 -1236 0z"/>
                                <path d="M8537 8360 c-78 -20 -125 -58 -146 -119 -35 -106 26 -214 141 -246
                                            55 -15 108 -17 497 -14 240 2 447 -1 461 -5 44 -15 87 -54 111 -103 l24 -48 5
                                            -1915 c3 -1053 2 -1929 -3 -1946 -9 -38 -50 -81 -97 -103 -31 -13 -90 -15
                                            -465 -12 -450 3 -534 -3 -605 -39 -67 -34 -98 -134 -69 -221 26 -78 87 -126
                                            179 -142 19 -4 321 -7 670 -8 578 -1 639 1 678 16 87 35 117 104 133 310 16
                                            200 7 4408 -9 4457 -18 53 -59 98 -112 121 -44 20 -65 21 -700 24 -390 1 -670
                                            -2 -693 -7z"/>
                                <path d="M5812 8063 c-18 -9 -41 -28 -51 -42 -47 -68 -46 -49 -48 -886 -1
                                            -434 -4 -811 -7 -837 -6 -58 -38 -122 -71 -144 -45 -29 -91 -32 -437 -22 -324
                                            9 -346 9 -393 -9 -56 -21 -95 -68 -95 -114 0 -60 30 -107 118 -186 121 -108
                                            779 -762 859 -853 88 -100 117 -120 186 -127 112 -11 108 -15 552 431 220 221
                                            445 442 500 491 131 116 144 131 167 183 22 49 19 79 -15 130 -40 59 -73 63
                                            -472 56 l-350 -6 -45 24 c-36 19 -51 36 -74 82 l-30 58 0 802 c-1 726 -3 806
                                            -18 850 -20 57 -59 102 -107 122 -45 19 -128 17 -169 -3z"/>
                            </g>
                        </svg>
                    </div> */}

                <div className='archiveGalleryImages'>
                    {archiveGalleryFields.map((val, idx) => (
                        <>

                            {/* <SwiperSlide key={val} virtualIndex={idx}> */}

                            {/* <LazyLoadImage src={val.asset._ref} key={idx} id="galImage" width={val.width} height={val.height} style={{ position: 'absolute', left: val.left, right: val.right, top: val.top, bottom: val.bottom }} /> */}

                            <LazyLoadImage src={urlFor(val).url()} key={idx} id="galImage" width={val.width} height={val.height} style={{ position: 'absolute', top: val.top, left: val.left }} alt={val.alt} />


                            {console.log(val)}

                            {/* </SwiperSlide> */}

                        </>
                    ))}

                </div>

                {/* <div className='artext-wrapper'>
                        <button onClick={exitSlideshow}>EXIT</button>
                    </div>
                    <div className="next-arrow" onClick={handleNext}>
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1181.000000 1181.000000" width={30} height={30} style={{ rotate: "-90deg" }}
                            className="arrow-logo" fill="black" stroke="none">
                            <g transform="translate(0.000000,1181.000000) scale(0.100000,-0.100000)">
                                <path d="M2015 8360 c-102 -8 -147 -25 -193 -71 -68 -69 -63 121 -60 -2369 l3
                                            -2255 22 -58 c26 -70 56 -108 108 -139 l40 -23 655 0 655 0 57 23 c102 41 147
                                            114 135 219 -6 58 -31 92 -88 120 -76 39 -182 46 -583 41 -402 -6 -454 -2
                                            -525 45 -56 37 -75 100 -61 200 7 46 10 716 10 1887 0 1149 3 1827 9 1848 19
                                            63 50 100 108 129 l57 27 410 -2 c260 -2 431 1 466 8 133 26 195 87 195 194 0
                                            47 -5 68 -23 93 -26 37 -88 69 -161 83 -60 11 -1087 11 -1236 0z"/>
                                <path d="M8537 8360 c-78 -20 -125 -58 -146 -119 -35 -106 26 -214 141 -246
                                            55 -15 108 -17 497 -14 240 2 447 -1 461 -5 44 -15 87 -54 111 -103 l24 -48 5
                                            -1915 c3 -1053 2 -1929 -3 -1946 -9 -38 -50 -81 -97 -103 -31 -13 -90 -15
                                            -465 -12 -450 3 -534 -3 -605 -39 -67 -34 -98 -134 -69 -221 26 -78 87 -126
                                            179 -142 19 -4 321 -7 670 -8 578 -1 639 1 678 16 87 35 117 104 133 310 16
                                            200 7 4408 -9 4457 -18 53 -59 98 -112 121 -44 20 -65 21 -700 24 -390 1 -670
                                            -2 -693 -7z"/>
                                <path d="M5812 8063 c-18 -9 -41 -28 -51 -42 -47 -68 -46 -49 -48 -886 -1
                                            -434 -4 -811 -7 -837 -6 -58 -38 -122 -71 -144 -45 -29 -91 -32 -437 -22 -324
                                            9 -346 9 -393 -9 -56 -21 -95 -68 -95 -114 0 -60 30 -107 118 -186 121 -108
                                            779 -762 859 -853 88 -100 117 -120 186 -127 112 -11 108 -15 552 431 220 221
                                            445 442 500 491 131 116 144 131 167 183 22 49 19 79 -15 130 -40 59 -73 63
                                            -472 56 l-350 -6 -45 24 c-36 19 -51 36 -74 82 l-30 58 0 802 c-1 726 -3 806
                                            -18 850 -20 57 -59 102 -107 122 -45 19 -128 17 -169 -3z"/>
                            </g>
                        </svg>
                    </div>
                </Swiper> */}

                {/* <LocomotiveScrollProvider
                        options={
                            {
                                smooth: true
                            }
                        }
                        location={asPath}
                        containerRef={containerRef}
                    onLocationChange={scroll => scroll.scrollTo(mouseX, { duration: 0, disableLerp: true })} // If you want to reset the scroll position to 0 for example
                    onUpdate={() => console.log('Updated, but not on location change!')} // Will trigger on 

                    >
                        <main data-scroll-container className='archiveGallery' ref={containerRef}>

                            <div className='archiveGallery'>
                            {archiveGallery.map((val, idx) => (
                                <div onClick={toggleSlideshow} className="galImageWrapper" data-scroll-ease="0.074">
                                    <LazyLoadImage src={val} key={idx} onClick={(e) => handleIdx(e)} id={`${idx + 1}`} className="galImage" width={"100%"} />
                                </div>
                            ))}
                            </div>
                        </main>
                    </LocomotiveScrollProvider> */}

            </>
        );

    } else {
        return <div className="loading">Loading...</div>
    }


}

export default Archive;