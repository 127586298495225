import React, { useEffect, useState } from 'react';
import sanityClient from "../../client.js";
import TypeWriterEffect from 'react-typewriter-effect';
import { Link } from "react-router-dom";
import CountUp from 'react-countup';
import ProgressBar from "../../components/progressBar/progressBar";
import Footer from '../../components/footer/footer';

import { LazyLoadImage } from "react-lazy-load-image-component";
import { gsap, Power2 } from "gsap";

import './index.scss';

function Index() {
    const myRef = document.querySelector('.scrollable-div')
    const [completed, setCompleted] = useState(0);

    const [loading, setLoading] = useState(false);
    const [loadingMain, setMainLoading] = useState(false);

    const [indexData, setIndexData] = useState([]);
    const [indexMainData, setIndexMainData] = useState([]);


    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "project"] | order(publishedAt){
                title,
                subtitle,
                publishedAt,
                featured,
                featuredDesc,
                "bannerGIF": bannerGIF.asset->url,
                _id,
                type,
                slug                
            }`
            )
            .then(setLoading(true))
            .then(indexData => setTimeout(() => handleIndexData(indexData), 3000))
            .catch(console.error);
    }, []);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "main"]{
                homeScript,
                displayHomeVideo,
                homeVideo,
                displayHomeImage,
                "homeImage": homeImage.asset->url      
                }`
            )
            .then(setMainLoading(true))
            .then(indexMainData => setTimeout(() => handleMainIndexData(indexMainData), 3000))
            .catch(console.error);
    }, []);

    useEffect(() => {
        setInterval(() => setCompleted(100), 1000);
    }, []);

    const featuredProjects = indexData.filter(project => {
        return project.featured === true;
    });

    useEffect(() => {
        let title = document.querySelector('#featuredTitleOne');
        let desc1 = document.querySelector('#descOne');
        let title2 = document.querySelector('#featuredTitleTwo');
        let desc2 = document.querySelector('#descTwo');
        let title3 = document.querySelector('#featuredTitleThree');
        let desc3 = document.querySelector('#descThree');

        let indexTimeline = gsap.timeline();

        let featuredWork = [title, desc1, title2, desc2, title3, desc3];
        if (featuredWork.some((el)=> !el)) return;

        // indexTimeline.to(featuredWork, {
        //     duration: 1,
        //     ease: Power2.easeOut,
        //     stagger: 0.35,
        //     opacity: 1,
        //     delay: 9.5
        // })
    });

    const handleIndexData = (data) => {
        setIndexData(data)
        setLoading(false)
    }

    const handleMainIndexData = (data) => {
        setIndexMainData(data)
        setMainLoading(false)
        setupAnimation();
    }

    const setupAnimation = () => {
        const animationTimeline = gsap.timeline();     
        const mediaEl = document.querySelector('.index-media-container');
        animationTimeline.to(mediaEl, {
            duration: 1,
            ease: Power2.easeOut,
            stagger: 0.35,
            opacity: 1,
            delay: 12.5
        })
    }

    let startLoading = (
        <div className='loadingContainer'>
            <div className='loadingScreen'>
                <ProgressBar bgcolor={"white"} completed={completed} />
                <div className='progressText'>
                    <span><CountUp
                        start={0}
                        end={100}
                    />%</span>
                    <span>https://www.03am.co.uk/storage/09234241c4d81dc9ef5ae75558629e-758x615.png</span>
                </div>
            </div>
        </div>
    )

    let indexContainer = (
        <div className='index'>
            <div className='index-container'>
                <div className='loading-script'>
                    {indexMainData &&
                        indexMainData.map((indexData, idx) => (
                            <TypeWriterEffect
                                key={idx}
                                className="scriptOne"
                                startDelay={0}
                                cursorColor="black"
                                text={indexData.homeScript[0].children[0].text}
                                typeSpeed={15}
                                scrollArea={myRef}
                            />
                        ))}
                </div>
                <Footer />
            </div>
            <Link to="/projects">
                <div className='index-media-container'>
                    <div className='bg-video'>
                        {(indexMainData.length !== 0 && indexMainData[0].displayHomeVideo === true) ? <iframe loading="lazy" id="idx-video" src={`${indexMainData[0].homeVideo} + '&autoplay=1&amp;loop=1&amp;background=1'`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" title="03AM Showreel" data-ready="true" style={{ width: '1415.11px', height: '660px' }}></iframe> : ''}
                        {(indexMainData.length !== 0 && indexMainData[0].displayHomeImage === true) ? <LazyLoadImage id="idx-image" src={indexMainData[0].homeImage} alt="index-image" /> : ''}
                    </div>
                </div>
            </Link>
        </div>
    )


    return (
        <div className="App">
            {loading && loadingMain && indexMainData.length === 0 ? startLoading : indexContainer}
        </div>
    );

}

export default Index;