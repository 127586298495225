import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import { ThemeContext, themes } from '../themeContext';
import sanityClient from "../../client.js";

import "./headerMobile.scss";

function HeaderMobile() {

    const [footerData, setFooterData] = useState([]);
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "main"]{
                    infoInstagram,
                    infoVimeo,
                    infoEmail,
                    infoCopyright
            }`
            )
            .then((data) => setFooterData(data[0]))
            .catch(console.error);
    }, []);

    const [darkMode, setDarkMode] = React.useState( true );

    return (
        <div className="headerMobile">
            <div className="logoHeader">
                <Link
                    to="/">03AM</Link>
            </div>
            <div className="darkMode">
                <ThemeContext.Consumer>
                    {({ changeTheme }) => (
                        <button
                            color="link"
                            onClick={() => {
                                setDarkMode(!darkMode);
                                changeTheme(darkMode ? themes.light : themes.dark);
                            }}
                        >
                            { darkMode ? <span className="darkText">:~$Dark</span> : <span className="lightText">:~$Light</span> }
                        </button>
                    )}
                </ThemeContext.Consumer>
            </div>
            <div className="top-nav">
                <Link to="/projects">Projects</Link>
                <Link to="/info">Info</Link>
                {/* <Link to="/archive">Archive</Link> */}
                <Link to="/diary">Diary</Link>
            </div>
            <div className="spacer"></div>
            <div className="bottom-nav">
                <a href={footerData.infoInstagram} target="_blank" rel="noreferrer">Instagram</a>
                <a href={footerData.infoVimeo} target="_blank" rel="noreferrer">Vimeo</a>
                <a href={`mailto:${footerData.infoEmail}`} target="_blank" rel="noreferrer">Email</a>
            </div>
            <div className="bottomest">
                <p>London, UK. All Rights Reserved © 03AM LTD</p>
            </div>
        </div>
    );
}

export default HeaderMobile;