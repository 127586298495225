import React ,{ useEffect, useState } from "react"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import sanityClient from "../client.js";
import styles from '../assets/styles/Diary.module.scss'
import {randomInt} from '../utils.mjs'


const Diary = () => {
    
    const [diaryData, setDiaryData] = useState([]);
    useEffect(() => sanityClient.fetch(
        `*[_type == "diary"] | order(date desc){
            title,
            date,
            "image": {
                "alt": image.alt,
                "src": image.asset->url
            }
        }`
    ).then((diaryData) => setDiaryData(diaryData))
    ,[])

    return (
        <div className={styles.diary}>
            <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2}}>
                <Masonry gutter={randomInt(150, 350) + 'px'} className={styles.masonry}>
                    {diaryData && diaryData.map((diary, idx) => (
                        <img key={idx} src={diary.image.src} alt={diary.image.alt || diary.title} className={styles.diaryImage} />
                    ))}
                </Masonry> 
            </ResponsiveMasonry>
        </div>
    )
}
export default Diary