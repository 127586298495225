import React, { useEffect, useState } from 'react';
import { gsap, Power2 } from "gsap";
import sanityClient from "../../client.js";

import TypeWriterEffect from 'react-typewriter-effect';
import Footer from '../../components/footer/footer';

import './info.scss';

function Info() {
    const myRef = document.querySelector('.container-info')

    const [infoData, setInfoData] = useState([]);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "main"]{
                infoContent,
                infoEmail,
                _id,
                contactLine,
                clientList,
                awardList
            }`
            )
            .then((infoData) => setInfoData(infoData) && console.log(infoData))
            .catch(console.error);
    }, []);

    useEffect(() => {
            let infos = document.querySelectorAll('.info > p');
            if (!infos) return 

            let infoTimeline = gsap.timeline();          

            infoTimeline.to(infos, {
                duration: 1,
                ease: Power2.easeOut,
                stagger: 0.35,
                opacity: 1,
                delay: 5.5
            })

    });

    if (infoData.length === 0) return <div className="loading">Loading...</div>;

    return (
        <>
            <div className='info'>
                {infoData &&
                    infoData.map((info, idx) => (
                        <React.Fragment key={idx}>
                            <p style={{ fontFamily: 'Grotesque-demi' }}>About</p>

                            <TypeWriterEffect
                                className="info-title"
                                startDelay={50}
                                cursorColor="black"
                                text={info.infoContent[0].children[0].text}
                                typeSpeed={20}
                                scrollArea={myRef}
                            />
                            <br />
                            <p className='info-title'>CONTACT</p>
                            <p className='info-value'>{info.infoEmail}</p>
                            <p className='info-value'>{info.contactLine != null ? info.contactLine : ''}</p>
                            <br />
                            {/* <p className='info-title'>Clients</p> 
                             <p className='info-value'>{info.clientList != null ? info.clientList[0].children[0].text : ''}</p> */}
                            <br />
                            {info.awardList != null ? <p className='info-title'>Awards</p> : ''}
                            {info.awardList != null ? <p className='info-value'>{info.awardList[0].children[0].text}</p> : ''}
                        </React.Fragment>
                    ))}

            </div>
            <Footer />

        </>
    );
}

export default Info;