const ProgressBar = (props) => {
    const { bgcolor, completed } = props;

    const containerStyles = {
        height: 20,
        width: '500px',
        backgroundColor: "white",
        border: '1px solid black',
        margin: '0 auto',
        marginBottom: 10
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: 'black',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
    }

    const labelStyles = {
        fontWeight: 'bold'
    }

    return (
        <div className="progressBar" style={containerStyles}>
            <div className="progressFill" style={fillerStyles}>
                <span style={labelStyles}></span>
            </div>
        </div>
    );
};

export default ProgressBar;