import React from "react";
import { NavLink, Link } from "react-router-dom";
import { ThemeContext, themes } from '../themeContext';

import "./header.scss";

function Header() {

    const [darkMode, setDarkMode] = React.useState( true );

    return (
        <div className="header-container">
            <div className="header">
                <div className="left-nav">
                    <Link to="/">03AM</Link>
                </div>
                <div className="center-nav">
                    <NavLink activeclassname="active" to="/projects">Projects</NavLink>
                    <NavLink activeclassname="active" to="/info">Info</NavLink>
                    {/* <NavLink activeclassname="active" to="/archive">Archive</NavLink> */}
                    <NavLink activeclassname="active" to="/diary">Diary</NavLink>
                </div>
                <div className="right-nav">
                    <ThemeContext.Consumer>
                        {({ changeTheme }) => (
                            <button
                                color="link"
                                onClick={() => {
                                    setDarkMode(!darkMode);
                                    changeTheme(darkMode ? themes.light : themes.dark);
                                }}
                            >
                                <span className="darkText">:~$Dark</span><span className="lightText" style={{ display: 'none' }}>:~$Light</span>
                            </button>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        </div>
    );
}

export default Header;