import React, { useEffect } from "react";

function ProjectsFilter( {posts, getter, onFilter}){

    const [filterOptions, setFilterOptions] = React.useState( [] )
    const setFilter = (option) => onFilter(option === getter ? undefined : option) 

    useEffect( () => {
        const filterOptions = new Set()
        posts.forEach( post => {
            if (post.type) filterOptions.add( post.type.toLowerCase() )
        })
        setFilterOptions( Array.from(filterOptions) )
    }, [posts]) 

    return  (
        <ul className="projectsFilter">
            { filterOptions.map( (option, i) => {
                return (
                    <li key={i} className={option === getter ? 'active' : ''} >
                        <button onClick={()=>setFilter(option)}>{option}</button>
                    </li>
                )
            })}
        </ul>)
}
export default ProjectsFilter
