import React, { useState, useEffect, useRef, useMemo } from 'react';
import sanityClient from "../../client.js";

import { NavLink } from 'react-router-dom';

import { gsap, Power1, ScrollTrigger } from "gsap/all";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CountUp from 'react-countup';
import ProgressBar from "../../components/progressBar/progressBar";

import TypeWriterEffect from 'react-typewriter-effect';
import { useLocomotiveScroll } from 'react-locomotive-scroll'

import { filterPostsByType } from '../../utils.mjs'
import ProjectsFilter from '../../components/ProjectsFilter'
import "./projects.scss";


function Projects() {
    const { scroll } = useLocomotiveScroll()

    const [postData1, setPostData1] = useState([]);
    const [activeFilter, setActiveFilter] = useState();
    const filteredPosts = useMemo(() => filterPostsByType(postData1, activeFilter), [postData1, activeFilter])

    const [infoData1, setInfoData1] = useState([]);

    let singleProject = useRef([]);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "project"] | order(publishedAt){
                title,
                subtitle,
                publishedAt,
                featured,
                _id,
                type,
                slug,
                "bannerGIF": bannerGIF.asset->url,
                "bannerStill": bannerStill.asset->url,
                videoURL
            }`
            )
            .then((postData1) => setPostData1(postData1))
            .catch(console.error);
    }, []);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "main"]{
                displayFeaturedImage,
                "featuredImage": featuredImage.asset->url,
                displayFeaturedVideo,
                featuredVideo,
                projectScript
            }`
            )
            .then((infoData1) => setInfoData1(infoData1))
            .catch(console.error);
    }, []);

    useEffect(() => {
        // f-y ftw
        const shuffleArray = array => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            return array;
        }
        setTimeout(() => {
            let projectsTimeline = gsap.timeline();

            const boxes = document.querySelectorAll('.galImage')

            // convert nodelist to array
            const arrBoxes = [...boxes];

            // shuffle boxes array
            let shuffledBoxes = shuffleArray(arrBoxes);


            projectsTimeline.to(shuffledBoxes, {
                duration: 0.6,
                ease: Power1.easeOut,
                stagger: 0.2,
                opacity: 1
            })
        }, 1000)
    });

    useEffect(() => {

        gsap.registerPlugin(ScrollTrigger)

        // f-y ftw
        const shuffleArray = array => {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            return array;
        }
        // setTimeout(() => {
        let projectsTimeline = gsap.timeline();

        const boxes = document.querySelectorAll('.title-box')

        // convert nodelist to array
        const arrBoxes = [...boxes];

        // shuffle boxes array
        let shuffledBoxes = shuffleArray(arrBoxes);

        let firstTitleBox = document.querySelector('.title-box')

        // projectsTimeline.to(boxes, {
        //     opacity: 1,
        //     scrollTrigger: {
        //         trigger: boxes,
        //         markers: true,
        //         start: "top top",
        //         end: "+=500",
        //     }
        // })

        // projectsTimeline.to(shuffledBoxes, {
        //     opacity: 1,
        //     duration: 0.6,
        //     ease: Power2.easeOut,
        //     stagger: 0.2
        // })




        // gsap.scrollTrigger({ trigger: shuffledBoxes, markers: true })

        // }, 1000)
    });

    if (postData1.length === 0 || infoData1.length === 0) return <div className="loading">Loading...</div>;


    function addLeadingZeros(num, totalLength) {
        return String(num).padStart(totalLength, '0');
    }


    const imageContent = infoData1.filter(info => {
        if (info.displayFeaturedImage === true) {
            return true;
        };
        return false;
    })

    const videoContent = infoData1.filter(info => {
        if (info.displayFeaturedVideo === true) {
            return true;
        };
        return false;
    })

    const toggleProjects = () => {
        window.scrollTo({
            top: 1050,
            behavior: 'smooth',
        });
    }


    // let startLoading = (
    //     <div className='loadingContainer'>
    //         <div className='loadingScreen'>
    //             <ProgressBar bgcolor={"white"} completed={completed} />
    //             <div className='progressText'>
    //                 <CountUp
    //                     start={0}
    //                     end={100}
    //                 />
    //                 <span>% https://www.03am.co.uk/storage/09234241c4d81dc9ef5ae75558629e-758x615.png</span>
    //             </div>
    //         </div>
    //     </div>
    // )


    let projectsContainer = (
        <>
            <div className='projects-container'>
                <div className="projects-featured">
                    <div className='featured-wrapper'>
                        {/* {infoData1 && infoData1.map((info, idx) => (
                            <div className='text-content'>
                                <TypeWriterEffect
                                    key={idx}
                                    className="scriptText"
                                    startDelay={50}
                                    cursorColor="black"
                                    text={info.projectScript[0].children[0].text}
                                    typeSpeed={20}
                                />
                            </div>
                        ))} */}
                        {imageContent && imageContent.map((content, idx) => (
                            <>
                                <LazyLoadImage className="featured-image" key={idx} src={content.featuredImage} width={758} height={600} alt="featured-image" />
                                <div onClick={toggleProjects} style={{ cursor: 'pointer', textAlign: "center" }}>
                                    {/* <img className="arrow-logo" style={{ marginTop: "40px" }} src={arrow} width={30} height={30} /> */}
                                    <svg loading="lazy" version="1.0" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 1181.000000 1181.000000" width="30" height="30" style={{ marginTop: "40px" }}
                                        className="arrow-logo" fill="black" stroke="none">
                                        <g transform="translate(0.000000,1181.000000) scale(0.100000,-0.100000)">
                                            <path d="M2015 8360 c-102 -8 -147 -25 -193 -71 -68 -69 -63 121 -60 -2369 l3
                                            -2255 22 -58 c26 -70 56 -108 108 -139 l40 -23 655 0 655 0 57 23 c102 41 147
                                            114 135 219 -6 58 -31 92 -88 120 -76 39 -182 46 -583 41 -402 -6 -454 -2
                                            -525 45 -56 37 -75 100 -61 200 7 46 10 716 10 1887 0 1149 3 1827 9 1848 19
                                            63 50 100 108 129 l57 27 410 -2 c260 -2 431 1 466 8 133 26 195 87 195 194 0
                                            47 -5 68 -23 93 -26 37 -88 69 -161 83 -60 11 -1087 11 -1236 0z"/>
                                            <path d="M8537 8360 c-78 -20 -125 -58 -146 -119 -35 -106 26 -214 141 -246
                                            55 -15 108 -17 497 -14 240 2 447 -1 461 -5 44 -15 87 -54 111 -103 l24 -48 5
                                            -1915 c3 -1053 2 -1929 -3 -1946 -9 -38 -50 -81 -97 -103 -31 -13 -90 -15
                                            -465 -12 -450 3 -534 -3 -605 -39 -67 -34 -98 -134 -69 -221 26 -78 87 -126
                                            179 -142 19 -4 321 -7 670 -8 578 -1 639 1 678 16 87 35 117 104 133 310 16
                                            200 7 4408 -9 4457 -18 53 -59 98 -112 121 -44 20 -65 21 -700 24 -390 1 -670
                                            -2 -693 -7z"/>
                                            <path d="M5812 8063 c-18 -9 -41 -28 -51 -42 -47 -68 -46 -49 -48 -886 -1
                                            -434 -4 -811 -7 -837 -6 -58 -38 -122 -71 -144 -45 -29 -91 -32 -437 -22 -324
                                            9 -346 9 -393 -9 -56 -21 -95 -68 -95 -114 0 -60 30 -107 118 -186 121 -108
                                            779 -762 859 -853 88 -100 117 -120 186 -127 112 -11 108 -15 552 431 220 221
                                            445 442 500 491 131 116 144 131 167 183 22 49 19 79 -15 130 -40 59 -73 63
                                            -472 56 l-350 -6 -45 24 c-36 19 -51 36 -74 82 l-30 58 0 802 c-1 726 -3 806
                                            -18 850 -20 57 -59 102 -107 122 -45 19 -128 17 -169 -3z"/>
                                        </g>
                                    </svg>

                                </div>

                            </>

                        ))}


                        {videoContent && videoContent.map((content, idx) => (
                            <>
                                <div className='featured-video-container'>
                                    <iframe key={idx} title="featured-video" loading="lazy" src={content.featuredVideo} className='responsive-iframe' frameBorder="true" allowFullScreen></iframe>
                                </div>
                                <script src="https://player.vimeo.com/api/player.js"></script>
                            </>

                        ))}

                    </div>
                </div>


                <ProjectsFilter posts={postData1} getter={activeFilter} onFilter={setActiveFilter} />
                <ul className="projects">
                    {postData1 &&
                        filteredPosts.map((project, idx) => (
                            <li key={idx}>
                                <div className="title-box">
                                    <NavLink to={`/project/${project.slug.current}`}>
                                        <div className='banner-image' style={{ background: `url(${project.bannerStill}) no-repeat center` }}>
                                            <LazyLoadImage className="banner-gif" src={project.bannerGIF} alt="banner gif" />
                                        </div>

                                    </NavLink>
                                    <p className="title" ref={(el) => {
                                        singleProject.current[idx] = el;
                                    }}><span style={{ marginRight: '10px' }}>{addLeadingZeros(idx, 2).toString()}</span>{project.title}<span style={{ float: 'right' }}>{project.type}</span></p>

                                </div>
                                <span className="description"> {project.description}</span>

                            </li>
                        ))}
                </ul>
            </div>
        </>
    );

    return (
        <div className="App">
            {projectsContainer}
        </div>
    );
}

export default Projects;