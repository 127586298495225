import React, { useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";

import Header from "./components/header/header";
import NavBar from "./components/navbar/navBar";

import Index from "./pages/index/index";
import Info from "./pages/info/info";
import Archive from "./pages/archive/archive";
import Projects from "./pages/projects/projects";
import SingleProject from "./pages/singleProject/singleProject";
import Diary from "./pages/Diary";

import "the-new-css-reset/css/reset.css";
import './assets/styles/App.scss'

function App() {
  const containerRef = useRef(null);

  return (
    // <LocomotiveScrollProvider
    //   options={{
    //     smooth: true,
    //   }}
    //   watch={
    //     [
    //       //..all the dependencies you want to watch to update the scroll.
    //       //  Basicaly, you would want to watch page/location changes
    //       //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
    //     ]
    //   }
    //   containerRef={containerRef}
    //   onLocationChange={(scroll) =>
    //     scroll.scrollTo(0, { duration: 0, disableLerp: true })
    //   }
    //   onUpdate={() => console.log("Updated, but not on location change!")}
    // >
    // <main data-scroll-container ref={containerRef}>
    <main>
      <div>
        <Router>
          <Header />
          <NavBar />
          <Routes>
            <Route index element={<Index />} />
            <Route path="/project/:slug" element={<SingleProject />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/info" element={<Info />} />
            {/* <Route path="/archive" element={<Archive />} /> */}
            <Route path="/diary" element={<Diary />} />
          </Routes>
        </Router>
      </div>
    </main>
    // </LocomotiveScrollProvider>
  );
}

export default App;
