import React from 'react';
import styled from 'styled-components';
import HeaderMobile from '../headerMobile/headerMobile';

const Ul = styled.ul`
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 3;
    transition: transform 0.3s ease-in-out;
  }
`;

const RightNav = ({ open }) => {
  return (
    <Ul id="sideNav" open={open}>
      {/* <li>Home</li>
      <li>About Us</li>
      <li>Contact Us</li>
      <li>Sign In</li>
      <li>Sign Up</li> */}
      <HeaderMobile />
    </Ul>
  )
}

export default RightNav;