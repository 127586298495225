import React, { useEffect, useState } from 'react';
import sanityClient from "../../client.js";

import './footer.scss';

function Footer() {

    const [footerData, setFooterData] = useState([]);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "main"]{
                    infoInstagram,
                    infoVimeo,
                    infoEmail,
                    infoCopyright
            }`
            )
            .then((footerData) => setFooterData(footerData))
            .catch(console.error);
    }, []);


    return (
        footerData &&
            footerData.map((footer, idx) => (
                <div className='footer-container' key={idx}>
                    <div className="footerLink">
                        <a href={footer.infoInstagram} target="_blank" rel="noreferrer">Instagram</a>
                    </div>
                    <div className="footerLink">
                        <a href={footer.infoVimeo} target="_blank" rel="noreferrer">Vimeo</a>
                    </div>
                    <div className="footerLink">
                        <a href={`mailto:${footer.infoEmail}`} target="_blank" rel="noreferrer">{footer.infoEmail}</a>
                    </div>
                    <div className="footerLink">
                        <p>{footer.infoCopyright}</p>
                    </div>
                </div>
            ))
    );
}

export default Footer;