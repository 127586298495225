import React from 'react';
import styled from 'styled-components';
import Burger from './burger';
import { Link } from 'react-router-dom';

const Nav = styled.nav`
  width: 100%;
  display: none;
  justify-content: space-between;
  .logoHeader {
    padding: 15px 0px;
  }
  @media (max-width: 768px) {
    display: inline-block;
  }
`

const Navbar = () => {
  return (
    <Nav>
      <div className="logoHeader">
        <Link to="/">03AM</Link>
      </div>
      <Burger />
    </Nav>
  )
}

export default Navbar;