import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";

import SwiperCore, { Navigation, Pagination, A11y, Virtual } from 'swiper';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';

import Vimeo from '@u-wave/react-vimeo';

import "./singleProject.scss";

import 'swiper/scss';
import 'swiper/scss/virtual';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const SingleProject = () => {
    const navigate = useNavigate();

    const [singleProject, setSingleProject] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [swiper, setSwiper] = useState(null);

    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!swiper) return;
        swiper.slidePrev();
    }, [swiper]);

    const handleNext = useCallback(() => {
        if (!swiper) return;
        swiper.slideNext();
    }, [swiper]);

    const [currentSlide, setCurrentSlide ] = useState(0);

    const handleSubmit = (e) => {
        e.preventDefault();
        const creditsValue = document.querySelectorAll(".credits");
        const exitValue = document.querySelectorAll(".exit");
        var incorrectValue = document.querySelector('.incorrect');

        if (inputValue === '00') {
            incorrectValue.innerHTML = "";
            exitValue.forEach(exit => exit.style.display = 'none');
            creditsValue.forEach(credit => credit.style.display = 'block');
        } else if (inputValue === '01') {
            incorrectValue.innerHTML = "";
            creditsValue.forEach(credit => credit.style.display = 'none');
            exitValue.forEach(exit => exit.style.display = 'block');
        } else {
            creditsValue.forEach(credit => credit.style.display = 'none');
            exitValue.forEach(exit => exit.style.display = 'none');
            incorrectValue.innerHTML = 'Error: incorrect Input';
        }
    };


    const { slug } = useParams();

    useEffect(() => {
        sanityClient
            .fetch(
                `*[slug.current == "${slug}"]{
                _id,
                slug,
                title,
                subtitle,
                description,
                publishedAt,
                _id,
                "imageSet": imageSet[].asset->url,
                type,
                credits,
                videoURL
            }`)
            .then((data) => setSingleProject(data))
            .catch(console.error);

    }, [slug]);



    useEffect(() => {
        const keydownHandler = e => {
            if (swiper && e.keyCode === 39) {
                e.preventDefault();
                swiper.slideNext();
            }

            if (swiper && e.keyCode === 37) {
                e.preventDefault();
                swiper.slidePrev();
            }
        }

        document.addEventListener('keydown', keydownHandler);

        return () => {
            document.removeEventListener('keydown', keydownHandler);
        }
    }, [swiper])

    if (!singleProject) return <div className="loading">Loading...</div>;

    const imageGallery = singleProject[0].imageSet;


    const pagination = {
        clickable: true,
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
            return `<span class="${currentClass}"></span>-<span class="${totalClass}"></span>`;
        }
    };

    // If there is no image gallery
    if (imageGallery === null) {

        return <>
            <Swiper
                className='swiper-pagination-disabled'
                ref={sliderRef}
                modules={[Navigation, Pagination, A11y, Virtual]}
                spaceBetween={5}
                slidesPerView={1}
                speed={0}
                virtual
                pagination={{
                    clickable: true,
                    type: 'fraction',
                }}
                onPaginationUpdate={(swiper)=>setCurrentSlide(swiper.activeIndex + 1)}
                navigation={{
                    prevEl: '.prev-arrow',
                    nextEl: '.next-arrow'
                }}
                keyboard={{
                    enabled: true,
                    onlyInViewport: true
                }}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 200
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    }
                }}
            >

                {singleProject[0].videoURL &&
                <SwiperSlide>
                    <Vimeo
                        video={singleProject[0].videoURL}
                        height={615}
                        width={1200}
                        autoplay
                        className='video'
                    />
                </SwiperSlide>}

            </Swiper>

            <div className='wrapper-text'>
                <div className='text-wrapper'>
                    <p>1-1</p>
                    <p>{singleProject[0].title} {">"} {singleProject[0].subtitle}</p>
                    <div className='buttonWrapper' onClick={() => navigate(-1)}>
                        <p className="sProjectButton">EXIT</p>
                    </div>
                </div>
            </div>
        </>
    }


    // If there is an image gallery

    return (
        <div className='single-project'>

            <Swiper
                className='swiper-pagination-disabled'
                ref={sliderRef}
                modules={[Navigation, Pagination, A11y, Virtual]}
                spaceBetween={5}
                slidesPerView={1}
                pagination={pagination}
                onPaginationUpdate={(swiper)=>setCurrentSlide(swiper.activeIndex)}
                speed={0}
                virtual
                navigation={{
                    prevEl: '.prev-arrow',
                    nextEl: '.next-arrow'
                }}
                keyboard={{
                    enabled: true,
                    onlyInViewport: false
                }}
                mousewheel={true}
                onSwiper={setSwiper}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 200
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    }
                }}
            >
                <div className="prev-arrow">
                    {/* <img style={{ rotate: "180deg" }} src={arrow} width={30} height={20} /> */}
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1181.000000 1181.000000" width={30} height={30} style={{ rotate: "90deg" }}
                        className="arrow-logo" fill="black" stroke="none">
                        <g transform="translate(0.000000,1181.000000) scale(0.100000,-0.100000)">
                            <path d="M2015 8360 c-102 -8 -147 -25 -193 -71 -68 -69 -63 121 -60 -2369 l3
                                            -2255 22 -58 c26 -70 56 -108 108 -139 l40 -23 655 0 655 0 57 23 c102 41 147
                                            114 135 219 -6 58 -31 92 -88 120 -76 39 -182 46 -583 41 -402 -6 -454 -2
                                            -525 45 -56 37 -75 100 -61 200 7 46 10 716 10 1887 0 1149 3 1827 9 1848 19
                                            63 50 100 108 129 l57 27 410 -2 c260 -2 431 1 466 8 133 26 195 87 195 194 0
                                            47 -5 68 -23 93 -26 37 -88 69 -161 83 -60 11 -1087 11 -1236 0z"/>
                            <path d="M8537 8360 c-78 -20 -125 -58 -146 -119 -35 -106 26 -214 141 -246
                                            55 -15 108 -17 497 -14 240 2 447 -1 461 -5 44 -15 87 -54 111 -103 l24 -48 5
                                            -1915 c3 -1053 2 -1929 -3 -1946 -9 -38 -50 -81 -97 -103 -31 -13 -90 -15
                                            -465 -12 -450 3 -534 -3 -605 -39 -67 -34 -98 -134 -69 -221 26 -78 87 -126
                                            179 -142 19 -4 321 -7 670 -8 578 -1 639 1 678 16 87 35 117 104 133 310 16
                                            200 7 4408 -9 4457 -18 53 -59 98 -112 121 -44 20 -65 21 -700 24 -390 1 -670
                                            -2 -693 -7z"/>
                            <path d="M5812 8063 c-18 -9 -41 -28 -51 -42 -47 -68 -46 -49 -48 -886 -1
                                            -434 -4 -811 -7 -837 -6 -58 -38 -122 -71 -144 -45 -29 -91 -32 -437 -22 -324
                                            9 -346 9 -393 -9 -56 -21 -95 -68 -95 -114 0 -60 30 -107 118 -186 121 -108
                                            779 -762 859 -853 88 -100 117 -120 186 -127 112 -11 108 -15 552 431 220 221
                                            445 442 500 491 131 116 144 131 167 183 22 49 19 79 -15 130 -40 59 -73 63
                                            -472 56 l-350 -6 -45 24 c-36 19 -51 36 -74 82 l-30 58 0 802 c-1 726 -3 806
                                            -18 850 -20 57 -59 102 -107 122 -45 19 -128 17 -169 -3z"/>
                        </g>
                    </svg>
                </div>

                {singleProject[0].videoURL && 
                <SwiperSlide>
                    <Vimeo
                        video={singleProject[0].videoURL}
                        autoplay
                        className='video'
                    />
                </SwiperSlide>}

                {imageGallery.map((slideContent, index) => (
                    <SwiperSlide key={slideContent} virtualIndex={index}>
                        {/* <div className="prev-arrow" onClick={handlePrev}>
                            <img style={{ rotate: "180deg" }} src={arrow} width={30} height={20} />
                        </div> */}

                        <div className='video-wrapper' style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={slideContent} width={'auto'} height={600} alt={singleProject[0]?.title} />
                        </div>

                        {/* <div className="next-arrow" onClick={handleNext}>
                            <img src={arrow} width={30} height={20} />
                        </div> */}
                    </SwiperSlide>

                ))}
                <div className="next-arrow" >
                    {/* <img src={arrow} width={30} height={20} /> */}
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1181.000000 1181.000000" width={30} height={30} style={{ rotate: "-90deg" }}
                        className="arrow-logo" fill="black" stroke="none">
                        <g transform="translate(0.000000,1181.000000) scale(0.100000,-0.100000)">
                            <path d="M2015 8360 c-102 -8 -147 -25 -193 -71 -68 -69 -63 121 -60 -2369 l3
                                            -2255 22 -58 c26 -70 56 -108 108 -139 l40 -23 655 0 655 0 57 23 c102 41 147
                                            114 135 219 -6 58 -31 92 -88 120 -76 39 -182 46 -583 41 -402 -6 -454 -2
                                            -525 45 -56 37 -75 100 -61 200 7 46 10 716 10 1887 0 1149 3 1827 9 1848 19
                                            63 50 100 108 129 l57 27 410 -2 c260 -2 431 1 466 8 133 26 195 87 195 194 0
                                            47 -5 68 -23 93 -26 37 -88 69 -161 83 -60 11 -1087 11 -1236 0z"/>
                            <path d="M8537 8360 c-78 -20 -125 -58 -146 -119 -35 -106 26 -214 141 -246
                                            55 -15 108 -17 497 -14 240 2 447 -1 461 -5 44 -15 87 -54 111 -103 l24 -48 5
                                            -1915 c3 -1053 2 -1929 -3 -1946 -9 -38 -50 -81 -97 -103 -31 -13 -90 -15
                                            -465 -12 -450 3 -534 -3 -605 -39 -67 -34 -98 -134 -69 -221 26 -78 87 -126
                                            179 -142 19 -4 321 -7 670 -8 578 -1 639 1 678 16 87 35 117 104 133 310 16
                                            200 7 4408 -9 4457 -18 53 -59 98 -112 121 -44 20 -65 21 -700 24 -390 1 -670
                                            -2 -693 -7z"/>
                            <path d="M5812 8063 c-18 -9 -41 -28 -51 -42 -47 -68 -46 -49 -48 -886 -1
                                            -434 -4 -811 -7 -837 -6 -58 -38 -122 -71 -144 -45 -29 -91 -32 -437 -22 -324
                                            9 -346 9 -393 -9 -56 -21 -95 -68 -95 -114 0 -60 30 -107 118 -186 121 -108
                                            779 -762 859 -853 88 -100 117 -120 186 -127 112 -11 108 -15 552 431 220 221
                                            445 442 500 491 131 116 144 131 167 183 22 49 19 79 -15 130 -40 59 -73 63
                                            -472 56 l-350 -6 -45 24 c-36 19 -51 36 -74 82 l-30 58 0 802 c-1 726 -3 806
                                            -18 850 -20 57 -59 102 -107 122 -45 19 -128 17 -169 -3z"/>
                        </g>
                    </svg>
                </div>
                <div className='text-wrapper'>
                    <span>{currentSlide + 1}-{imageGallery.length + 1}</span>
                    <p>{singleProject[0].title} {">"} {singleProject[0].subtitle}</p>
                    {/* <button onClick={() => navigate(-1)}>EXIT</button> */}
                    <button onClick={() => navigate(-1)}>
                        EXIT
                    </button>
                </div>
            </Swiper>


        </div >
    );
}

export default SingleProject;